import 'rc-time-picker/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Button, Form,
  Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import Axios from 'axios';
import CustomModal from '../../component/common/CustomModal';
import {
  deliveryServiceability, hexLogs,
  storeProfile as profile, storeDeliveryStatusChange,
  deliveryBoy,
} from '../../assets/api/axios';
import CustomSwitch from '../../component/common/customSwitch/CustomSwitch';
import Permission from '../../access&permissions/permission';
import BusinessTimings from './BusinessTimings';
import DeliveryMap from './delivery-hex/DeliveryMap';
import DeliveryBoyDetails from './DeliveryBoyDetails';
import { dateString } from '../../utilities/Utils';
import { CustomTable } from '../../component/common';

function notValid(name, value) {
  switch (name) {
    case 'radius': {
      if (value.match('^[0-9]{0,3}([.][0-9]{0,1})?$') === null) {
        return true;
      }
      return false;
    }
    case 'amount': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    case 'pincode': {
      if (value.match('^[0-9]{0,6}$') === null) {
        return true;
      }
      return false;
    }
    case 'minimumShoppingAmount': {
      if (value.match('^[0-9]{0,4}$') === null) {
        return true;
      }
      return false;
    }
    case 'minDeliveryPenalty': {
      if (value.match('^[0-9]{0,3}$') === null) {
        return true;
      }
      return false;
    }
    case 'alternatePhoneNumber': {
      if (value.match('^[0-9]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    case 'accountName': {
      if (value.match('^[a-zA-Z0-9 ]{0,40}$') === null) {
        return true;
      }
      return false;
    }
    case 'accountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'confirmAccountNumber': {
      if (value.match('^[0-9]{0,18}$') === null) {
        return true;
      }
      return false;
    }
    case 'ifscCode': {
      if (value.match('^[0-9a-zA-Z]{0,11}$') === null) {
        return true;
      }
      return false;
    }
    case 'panNumber': {
      if (value.match('^[0-9a-zA-Z]{0,10}$') === null) {
        return true;
      }
      return false;
    }
    case 'gstNumber': {
      if (value.match('^[0-9a-zA-Z]{0,15}$') === null) {
        return true;
      }
      return false;
    }
    case 'customTempOffInterval': {
      if (new Date(value).getTime() < new Date().getTime()) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}

const getDateTimeFromInterval = (type, value) => {
  const currentDateTime = new Date();
  if (type === 'HOURS') {
    currentDateTime.setHours(currentDateTime.getHours() + value);
  } else if (type === 'DAYS') {
    currentDateTime.setDate(currentDateTime.getDate() + value);
  } else {
    currentDateTime.setMonth(currentDateTime.getMonth() + value);
  }
  return Math.round(currentDateTime.getTime() / 1000);
};

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
  });
};

class RetailerSettings extends React.Component {
  constructor(props) {
    super(props);
    const { userPermission } = this.props;
    const param = getQueryParams(props.history.location.search);
    this.checkPers = (pers) => pers.reduce(
      (acc, item) => acc || userPermission.includes(item), false,
    );
    this.state = {
      loading: false,
      error: false,
      rowsPerPage: param.l,
      page: param.p,
      param,
      storeProfile: null,
      deliveryTimeFrom: moment('10:00:00', 'HH:mm:ss'),
      deliveryTimeTo: moment('21:00:00', 'HH:mm:ss'),
      minimumShoppingAmount: '',
      paymentMode: [],
      alternatePhoneNumber: '',
      accountName: '',
      accountNumber: '',
      confirmAccountNumber: '',
      ifscCode: '',
      panNumber: '',
      gstNumber: '',
      showSuccessMessage: false,
      errors: {},
      showAccountDialog: false,
      isDelivering24hrs: false,
      hexLogsInfo: false,
      center: {
        lat: 0, lng: 0,
      },
      address: '',
      nonServiceableAreasPolygons: [],
      minDeliveryPenalty: '',
      deliveryRadii: [],
      pincode: '',
      landmark: '',
      showReasonDialog: false,
      storeTurningOff: false,
      statusChangeReason: '',
      statusChangeComment: '',
      isTemporaryOff: true,
      tempOffInterval: '',
      customTempOffInterval: '',
      deliveryOption: 'OWN_DELIVERY',
      isServiceableByDeliveryPartner: false,
      showFillBusinessDetailsAlert: false,
      isBlacklisted: false,
      isPermanentOff: false,
      storeOrLLTurnOff: 'STORE_TEMP_OFF',
      businessTimings: [],
      deliveryBoyDetails: [],
      updateHexRadius: false,
      deliveryRadiusStatus: '',
    };
    this.reasonCallback = null;
  }

  componentDidMount() {
    this.handleLoad(this.props);
    this.getHexLogs(this.props);
  }

  updateBusinessTimings = (updatedTimings) => {
    this.setState({ businessTimings: updatedTimings });
  };

  handleLoad = () => {
    const { storeCode } = this.props;
    profile(
      'get',
      storeCode,
    ).then((res) => {
      const storeProfile = res.data;
      this.setState(
        {
          storeProfile,
          loading: false,
          deliveryTimeFrom: storeProfile.deliveryTiming.startTime === ''
            ? moment('10:00:00', 'HH:mm:ss')
            : moment(storeProfile.deliveryTiming.startTime, 'HH:mm:ss'),
          deliveryTimeTo: storeProfile.deliveryTiming.endTime === ''
            ? moment('21:00:00', 'HH:mm:ss')
            : moment(storeProfile.deliveryTiming.endTime, 'HH:mm:ss'),
          isDelivering24hrs: storeProfile.isDelivering24hrs,
          // deliveryRadius: storeProfile.radius / 1000,
          minimumShoppingAmount: storeProfile.homeDeliveryMinAmount,
          paymentMode: [...storeProfile.paymentMode],
          alternatePhoneNumber: storeProfile.alternatePhoneNumber,
          accountName: storeProfile.account.accountName || '',
          accountNumber: storeProfile.account.accountNumber || '',
          confirmAccountNumber: storeProfile.account.accountNumber || '',
          ifscCode: storeProfile.account.ifscCode || '',
          panNumber: storeProfile.account.panNumber || '',
          gstNumber: storeProfile.account.gstNumber || '',
          center: { lat: storeProfile.latitude, lng: storeProfile.longitude },
          address: storeProfile.address,
          pincode: storeProfile.pinCode,
          landmark: storeProfile.landmark,
          minDeliveryPenalty: storeProfile.minDeliveryPenalty,
          businessTimings: [...storeProfile?.businessTimings],
          deliveryRadii: storeProfile.deliveryRadii.map((radii) => (
            {
              ...radii,
              radius: radii.radius / 1000,
            }
          )),
          nonServiceableAreasPolygons: storeProfile.nonServiceableAreasPolygons,
        }, () => {
          this.checkPartnerServiceability();
        },
      );
    });
    deliveryBoy(
      'get',
      storeCode,
    ).then((res) => {
      this.setState({
        deliveryBoyDetails: res.data.data,
      });
    });
  }

  checkPartnerServiceability = () => {
    const { storeProfile } = this.state;
    const { storeCode } = this.props;
    if (storeProfile.latitude === 0 && storeProfile.longitude === 0) {
      this.setState({
        isServiceableByDeliveryPartner: false,
        loading: false,
        showFillBusinessDetailsAlert: true,
      });
    } else if (storeProfile.isDeliverySupportOpted) {
      this.setState({
        isServiceableByDeliveryPartner: true,
        loading: false,
        deliveryOption: 'DELIVERY_SUPPORT',
      });
    } else {
      deliveryServiceability(
        'GET',
        storeCode,
      ).then((res) => {
        const { isServiceableByDeliveryPartner } = res.data;
        this.setState({
          isServiceableByDeliveryPartner,
          loading: false,
        });
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });
    }
  }

  initMap = () => {
    //
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({ [name]: value, errors });
  }

  handleChangeRadii = (index, event) => {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { deliveryRadii, errors } = this.state;
    errors[name] = null;
    const newDeliveryRedii = deliveryRadii.map((delivery, newIndex) => {
      if (index !== newIndex) {
        return delivery;
      }
      return { ...delivery, [name]: value };
    });
    this.setState(
      {
        deliveryRadii: newDeliveryRedii,
        errors,
      }, this.initMap,
    );
  }

  handleExtendRadii = (radii) => {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.push(radii);
    this.setState({
      deliveryRadii: newDelivery,
    }, this.initMap);
  }

  handleDeleteRadius = (index) => {
    const { deliveryRadii: newDelivery } = this.state;
    newDelivery.splice(index, 1);
    this.setState({
      deliveryRadii: newDelivery,
    }, this.initMap);
  }

  handleTimeChange = (name, time) => {
    this.setState({ [name]: time });
  }

  handleChangeDate = (event) => {
    const { name, value } = event.target;
    if (notValid(name, value)) {
      return;
    }
    const { errors } = this.state;
    errors[name] = null;
    this.setState({ [name]: new Date(value), errors });
  }

  handleChangePaymentMode = (code) => {
    const { paymentMode, errors } = this.state;
    const index = paymentMode.findIndex((mode) => mode.code === code);
    const updatedPaymentModes = Object.assign(
      [...paymentMode],
      {
        [index]: {
          ...paymentMode[index],
          isEnabled: !paymentMode[index].isEnabled,
        },
      },
    );

    errors.paymentMode = null;
    this.setState({
      paymentMode: updatedPaymentModes,
      errors,
    });
  }

  handle24hourDelivery = () => {
    const { isDelivering24hrs } = this.state;
    this.setState({ isDelivering24hrs: !isDelivering24hrs });
  }

  checkStoreDetails = () => {
    if (!this.validatedStoreDetails()) {
      return;
    }
    this.setState({
      showReasonDialog: true,
    });
    this.reasonCallback = this.handleSubmitStoreDetails;
  }

  getHexLogs = (data = {}) => {
    const { retailerDetails } = this.props;
    const {
      param,
    } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p,
    } = newParam;
    hexLogs(
      'GET',
      retailerDetails.code,
      {
        page: p,
        limit: l,
      },
    ).then((res) => {
      this.setState({
        hexLogsInfo: res.data,
        loading: false,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      });
    }).catch((error) => {
      console.error('Error occurred during hexLogs API call:', error);
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  validatedStoreDetails = () => {
    const {
      alternatePhoneNumber, paymentMode, pincode,
    } = this.state;
    const selectedPaymentModes = paymentMode
      .filter((mode) => mode.isEnabled === true);
    const errors = {};
    if (alternatePhoneNumber.length !== 0 && alternatePhoneNumber.toString().length < 10) {
      errors.alternatePhoneNumber = 'Alternate Mobile Number should contain 10 digits';
    } else if (alternatePhoneNumber.length !== 0 && alternatePhoneNumber.toString().match('^[1-9]{1}[0-9]{9}$') === null) {
      errors.alternatePhoneNumber = 'Please Enter Alternate Valid Mobile Number';
    }
    if (selectedPaymentModes.length === 0) {
      errors.paymentMode = 'Atleast one payment method is required';
    }
    if (pincode.length !== 0 && pincode.toString().length < 6) {
      errors.pincode = 'Pincode should contain 6 digits';
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  validatedAccountDetails = (required) => {
    const {
      accountName, accountNumber, confirmAccountNumber, ifscCode, panNumber, gstNumber,
    } = this.state;
    const errors = {};
    if (required && accountName === '') {
      errors.accountName = 'Account Name is required';
    } else if (accountName !== '' && accountName.length < 3) {
      errors.accountName = 'Account Name should contain atleast 3 characters';
    }
    if (required && accountNumber === '') {
      errors.accountNumber = 'Account Number is required';
    } else if (accountNumber !== '' && accountNumber.length < 5) {
      errors.accountNumber = 'Account Number should contain atleast 5 digits';
    }
    if (required && confirmAccountNumber === '') {
      errors.confirmAccountNumber = 'Confirm Account Number is required';
    } else if (confirmAccountNumber !== accountNumber) {
      errors.confirmAccountNumber = 'Account Number and Confirm Account Number does not match';
    }
    if (required && ifscCode === '') {
      errors.ifscCode = 'IFSC Code is required';
    } else if (ifscCode !== '' && ifscCode.match('^[a-zA-Z]{4}[0-9a-zA-Z]{7}$') === null) {
      errors.ifscCode = 'IFSC code should contain 11 characters (eg. SBIN0000121)';
    }
    if (required && panNumber === '') {
      errors.panNumber = 'PAN Number is required';
    } else if (panNumber !== '' && panNumber.match('^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$') === null) {
      errors.panNumber = 'PAN Number should contain 10 characters (eg. AAAPL1234C)';
    }
    if (gstNumber !== '' && gstNumber.match('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$') === null) {
      errors.gstNumber = 'GST Number should contain 15 characters (eg. 27AAAPL1234C1Z4)';
    }
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  handleSubmitStoreDetails = (accountPayload = {}) => {
    const {
      deliveryTimeFrom, deliveryTimeTo,
      minDeliveryPenalty, alternatePhoneNumber, minimumShoppingAmount, paymentMode,
      isDelivering24hrs, nonServiceableAreasPolygons, pincode, landmark, statusChangeComment,
      statusChangeReason, deliveryOption,
    } = this.state;

    let patchProfilePayload = (deliveryOption === 'DELIVERY_SUPPORT') ? {
      isDeliverySupportOpted: true,
    } : {
      isDeliverySupportOpted: false,
      minDeliveryPenalty: (parseInt(minimumShoppingAmount, 10) === 0 || !minDeliveryPenalty)
        ? 0 : parseInt(minDeliveryPenalty, 10),
      homeDeliveryMinAmount: !minimumShoppingAmount ? 0 : parseInt(minimumShoppingAmount, 10),
      paymentMode,
      alternatePhoneNumber,
      isDelivering24hrs,
      businessTimings: this.updateBusinessTimings,
      nonServiceableAreasPolygons,
      pinCode: pincode,
      landmark,
      reason: statusChangeReason === 'Others' ? statusChangeComment : statusChangeReason,
      ...accountPayload,
    };
    if (!isDelivering24hrs) {
      patchProfilePayload = {
        ...patchProfilePayload,
        deliveryTiming: {
          startTime: deliveryTimeFrom.format('HH:mm:ss'),
          endTime: deliveryTimeTo.format('HH:mm:ss'),
        },
      };
    }
    const { storeCode } = this.props;
    profile(
      'patch',
      storeCode,
      patchProfilePayload,
    )
      .then((res) => {
        this.setState({
          showSuccessMessage: true,
          statusChangeComment: '',
          statusChangeReason: '',
          storeProfile: res.data,
          updateHexRadius: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showSuccessMessage: false,
              showAccountDialog: false,
            });
          }, 2000);
        });
      });
  }

  validateDeliveryRadius = () => {
    const { deliveryRadii } = this.state;
    const errors = {};
    deliveryRadii.forEach((radii) => {
      if (radii.radius === '') {
        errors.radius = 'Delivery Radius is required';
      } else if (radii.radius.toString().match('^[0-9]{1,3}([.][0-9]{1})?$') === null) {
        errors.radius = 'Please provide a valid delivery radius (eg. 2.5 KM)';
      } else if (parseFloat(radii.radius) === 0) {
        errors.radius = 'Delivery Radius is required';
      }
      if (radii.amount === '') {
        errors.amount = 'Delivery Amount is required';
      }
    });
    if (Object.entries(errors).length === 0) {
      return true;
    }
    this.setState({ errors });
    return false;
  }

  handleUpdateRadius = () => {
    const { deliveryRadii } = this.state;
    const { storeCode } = this.props;
    if (!this.validateDeliveryRadius()) {
      return;
    }
    const newDeliveryRadii = deliveryRadii.map((radii) => (
      {
        ...radii,
        radius: parseFloat(radii.radius) * 1000,
        amount: parseInt(radii.amount, 10),
      }
    ));
    this.setState({
      deliveryRadiusStatus: 'loading',
    });
    profile(
      'PATCH',
      storeCode,
      {
        deliveryRadii: newDeliveryRadii,
      },
    ).then(() => {
      window.location.reload();
    }).catch(() => {
      this.setState({
        deliveryRadiusStatus: 'error',
      });
    });
  }

  handleSubmitAccountDetails = (isModal) => {
    const { storeProfile } = this.state;
    const cashPaymentMode = storeProfile
      ? storeProfile.paymentMode.find((mode) => mode.code === 'CASH')
      : null;
    const selectedPaymentModes = storeProfile
      ? storeProfile.paymentMode.filter((mode) => mode.isEnabled === true)
      : [];
    const isOptional = cashPaymentMode && cashPaymentMode.isEnabled
      && selectedPaymentModes.length === 1;
    if (!this.validatedAccountDetails(!isOptional || isModal)) {
      return;
    }

    this.setState({
      showReasonDialog: true,
    });
    this.reasonCallback = () => {
      const {
        accountName, accountNumber, ifscCode, panNumber, gstNumber, statusChangeReason,
        statusChangeComment,
      } = this.state;
      const { storeCode } = this.props;
      const accountPayload = {
        account: {
          accountName,
          accountNumber,
          ifscCode,
          panNumber,
          gstNumber,
          cancelledChequeUrl: '',
        },
        reason: statusChangeReason === 'Others' ? statusChangeComment : statusChangeReason,
      };
      if (isModal) {
        this.handleSubmitStoreDetails(accountPayload);
      } else {
        profile(
          'patch',
          storeCode,
          accountPayload,
        ).then((res) => {
          this.setState({
            showSuccessMessage: true,
            statusChangeComment: '',
            statusChangeReason: '',
            storeProfile: res.data,
          }, () => {
            setTimeout(() => {
              this.setState({
                showSuccessMessage: false,
                showAccountDialog: false,
              });
            }, 2000);
          });
        });
      }
    };
  }

  submitReason = () => {
    const {
      statusChangeReason, statusChangeComment, storeTurningOff, isTemporaryOff,
      tempOffInterval, customTempOffInterval,
    } = this.state;
    if (storeTurningOff && isTemporaryOff && (tempOffInterval === '' || (tempOffInterval === 'Custom' && customTempOffInterval === ''))) {
      this.setState({
        errors: {
          ...tempOffInterval === ''
            ? { tempOffInterval: 'In case of temporary off setting turn on time is required' }
            : { customTempOffInterval: 'In case of temporary off setting turn on time is required' },
        },
      });
      return;
    }
    if (statusChangeReason === '' || (storeTurningOff && statusChangeReason === 'Others' && statusChangeComment === '')) {
      this.setState({
        errors: {
          ...statusChangeReason === ''
            ? { statusChangeReason: 'Reason to change the settings is required' }
            : { statusChangeComment: 'Reason to change the settings is required' },
        },
      });
      return;
    }
    this.setState({ showReasonDialog: false });
    if (this.reasonCallback) {
      this.reasonCallback();
      this.reasonCallback = null;
    }
  }

  handleChangeDeliveryStatus = (event) => {
    const { storeProfile } = this.state;
    const { checked } = event.target;
    this.setState({
      showReasonDialog: true,
      storeTurningOff: storeProfile && storeProfile.isDelivering,
    });
    this.reasonCallback = () => {
      const {
        statusChangeComment, statusChangeReason, isTemporaryOff, tempOffInterval,
        customTempOffInterval, storeTurningOff, storeOrLLTurnOff, isPermanentOff,
      } = this.state;
      const { storeCode, updateRetailerDetails } = this.props;
      let turnOnTime = Math.round((new Date().getTime()) / 1000);
      if (storeTurningOff && isTemporaryOff) {
        if (tempOffInterval === 'Custom') {
          turnOnTime = Math.round(customTempOffInterval.getTime() / 1000);
        } else {
          switch (tempOffInterval) {
            case 'TWO_HOURS':
              turnOnTime = getDateTimeFromInterval('HOURS', 2);
              break;
            case 'ONE_DAYS':
              turnOnTime = getDateTimeFromInterval('DAYS', 1);
              break;
            case 'TWO_DAYS':
              turnOnTime = getDateTimeFromInterval('DAYS', 2);
              break;
            case 'ONE_WEEK':
              turnOnTime = getDateTimeFromInterval('DAYS', 7);
              break;
            case 'ONE_MONTH':
              turnOnTime = getDateTimeFromInterval('MONTH', 1);
              break;
            default:
              break;
          }
        }
      }

      storeDeliveryStatusChange(
        'patch',
        storeCode,
        {
          isDelivering: checked,
          state: checked ? 'SERVICE_ON' : storeOrLLTurnOff,
          reason: statusChangeReason === 'Others' ? statusChangeComment : statusChangeReason,
          ...storeTurningOff ? {
            shopTurnOnDate: turnOnTime,
            isPermanentlyClose: isPermanentOff,
          } : {},
        },
      ).then((res) => {
        updateRetailerDetails(res.data.data);
        this.setState({
          storeProfile: {
            ...storeProfile,
            ...res.data.data,
            isDelivering: res?.data?.data?.deliveryStatus,
          },
          showReasonDialog: false,
          statusChangeComment: '',
          statusChangeReason: '',
          tempOffInterval: '',
          customTempOffInterval: '',
          isTemporaryOff: true,
          isPermanentOff: false,
          isBlacklisted: false,
          storeOrLLTurnOff: 'STORE_TEMP_OFF',
        });
      }).catch(() => {});
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
      this.getHexLogs(data);
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

 convertMetersToKm = (meters) => {
   if (typeof meters !== 'number' || Number.isNaN(meters) || meters < 0) {
     return 'Invalid input';
   }
   const kilometers = meters * 0.001;
   return Number(kilometers.toFixed(3));
 };

 render() {
   const {
     loading, error, storeProfile, paymentMode, updateHexRadius,
     alternatePhoneNumber, showSuccessMessage, errors, minimumShoppingAmount,
     accountName, accountNumber, confirmAccountNumber, ifscCode, panNumber,
     gstNumber, showAccountDialog, minDeliveryPenalty,
     deliveryRadii, pincode, center, address, landmark, showReasonDialog,
     statusChangeReason, statusChangeComment, storeTurningOff,
     isTemporaryOff, tempOffInterval, customTempOffInterval, deliveryOption,
     isServiceableByDeliveryPartner, showFillBusinessDetailsAlert,
     isBlacklisted, storeOrLLTurnOff, isPermanentOff, businessTimings, deliveryRadiusStatus,
     deliveryBoyDetails, hexLogsInfo, param, page, rowsPerPage,
   } = this.state;

   const headers = [
     {
       key: 'createdAt',
       displayText: 'Created At',
       renderer: (data) => dateString(data.createdAt),
     },
     {
       key: 'platform',
       displayText: 'Platform',
     },
     {
       key: 'changedBy',
       displayText: 'Changed By',
     },
     {
       key: 'count',
       displayText: 'Hex Count',
     },
     {
       key: 'code',
       displayText: 'Radius',
       renderer: (data) => this.convertMetersToKm(data.radius),
     },
   ];

   const {
     location, isDetailPage, storeCode,
   } = this.props;
   const params = QueryString.parse(location.search);
   const state = (params.state || 'store-details').toLowerCase();
   const cashPaymentMode = storeProfile
     ? storeProfile.paymentMode.find((mode) => mode.code === 'CASH')
     : null;
   const selectedPaymentModes = storeProfile
     ? storeProfile.paymentMode.filter((mode) => mode.isEnabled === true)
     : [];
   const isOptional = cashPaymentMode && cashPaymentMode.isEnabled
      && selectedPaymentModes.length === 1;

   const getAccountDetailsBody = (isModal) => (
     <Container
       fluid
     >
       <Row>
         <Col
           xs={24}
         >
           <Row
             className="mt-4"
           >
             <Col>
               <div
                 className="mb-2"
               >
                 <b>
                   1. Account Name
                   {
                      isOptional && !isModal
                        ? ' (Optional)'
                        : (
                          <span className="text-danger">
                            *&nbsp;&nbsp;
                          </span>
                        )
                    }
                 </b>
               </div>
               <input
                 required
                 type="text"
                 name="accountName"
                 className={`form-control bg-white setup-input ${
                   errors.accountName ? 'border-danger' : ''}`}
                 value={accountName}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.accountName || ''}
               </div>
             </Col>
           </Row>
           <Row>
             <Col>
               <div
                 className="mb-2"
               >
                 <b>
                   2. Account Number
                   {
                      isOptional && !isModal
                        ? ' (Optional)'
                        : (
                          <span className="text-danger">
                            *&nbsp;&nbsp;
                          </span>
                        )
                    }
                 </b>
               </div>
               <input
                 required
                 type="text"
                 name="accountNumber"
                 className={`form-control bg-white setup-input ${
                   errors.accountNumber ? 'border-danger' : ''}`}
                 value={accountNumber}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.accountNumber || ''}
               </div>
             </Col>
           </Row>
           <Row>
             <Col>
               <div className="mb-2">
                 <b>
                   3. Confirm Account Number
                   {
                      isOptional && !isModal
                        ? ' (Optional)'
                        : (
                          <span className="text-danger">
                            *&nbsp;&nbsp;
                          </span>
                        )
                    }
                 </b>
               </div>
               <input
                 required
                 type="text"
                 name="confirmAccountNumber"
                 className={`form-control bg-white setup-input ${
                   errors.confirmAccountNumber ? 'border-danger' : ''}`}
                 value={confirmAccountNumber}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.confirmAccountNumber || ''}
               </div>
             </Col>
           </Row>
           <Row>
             <Col>
               <div
                 className="mb-2"
               >
                 <b>
                   4. IFSC Code
                   {
                      isOptional && !isModal
                        ? ' (Optional)'
                        : (
                          <span className="text-danger">
                            *&nbsp;&nbsp;
                          </span>
                        )
                    }
                 </b>
               </div>
               <input
                 required
                 type="text"
                 name="ifscCode"
                 className={`form-control bg-white setup-input ${
                   errors.ifscCode ? ' border-danger' : ''}`}
                 value={ifscCode}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.ifscCode || ''}
               </div>
             </Col>
           </Row>
           <Row>
             <Col>
               <div
                 className="mb-2"
               >
                 <b>
                   5. PAN Number
                   {
                      isOptional && !isModal
                        ? ' (Optional)'
                        : (
                          <span className="text-danger">
                            *&nbsp;&nbsp;
                          </span>
                        )
                    }
                 </b>
               </div>
               <input
                 required
                 type="text"
                 name="panNumber"
                 className={`form-control bg-white setup-input ${
                   errors.panNumber ? 'border-danger' : ''}`}
                 value={panNumber}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.panNumber || ''}
               </div>
             </Col>
           </Row>
           <Row>
             <Col>
               <div
                 className="mb-2"
               >
                 <b>
                   6. GST Number (Optional)
                   &nbsp;&nbsp;
                 </b>
               </div>
               <input
                 type="text"
                 name="gstNumber"
                 className={`form-control bg-white setup-input ${
                   errors.gstNumber ? ' border-danger' : ''}`}
                 value={gstNumber}
                 onChange={this.handleChange}
               />
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.gstNumber || ''}
               </div>
             </Col>
           </Row>
           {
              showSuccessMessage ? (
                <Row
                  className="m-0 mb-4 justify-content-center"
                >
                  <Col
                    xs="auto"
                    className="bg-primary-50 rounded py-2 text-white"
                  >
                    <b>Settings Saved</b>
                  </Col>
                </Row>
              ) : (
                <Row
                  className={`m-0 mb-4 ${this.checkPers([Permission.RETAILER_WRITE,
                    Permission.RETAILER_SETTING_ACCOUNT_DETAIL_WRITE]) ? '' : 'd-none'}`}
                >
                  <Col
                    className="p-0"
                  >
                    <Button
                      className="px-6 rounded-0"
                      onClick={() => {
                        this.handleSubmitAccountDetails(isModal);
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              )
            }
         </Col>
       </Row>
     </Container>
   );

   return (
     <>
       <div className="delivery-switch px-4 bg-white">
         <div className="text-muted text-right">
           <CustomSwitch
             {...this.props}
             id="home-delivery"
             displayTextLeft="Closed"
             displayTextRight="Open"
             onChange={this.handleChangeDeliveryStatus}
             checked={storeProfile ? storeProfile.isDelivering : false}
             disabled={!(
               this.checkPers([Permission.RETAILER_SETTING_DELIVERY_ON_OFF_ACCESS])
             )}
           />
         </div>
       </div>
       <Container
         fluid
         className="pt-8 pt-lg-1"
       >
         <CustomModal
           show={showAccountDialog}
           title="Account Details"
           body={(
             <div
               className="account-details-modal p-3"
             >
               {getAccountDetailsBody(true)}
             </div>
            )}
           onHide={() => this.setState({ showAccountDialog: false })}
         />
         <CustomModal
           show={showReasonDialog}
           title="Reason to Change Settings"
           body={(
             <div
               className="reason-modal p-3"
             >
               {
                  storeTurningOff ? (
                    <>
                      <div className="">
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          label="Temporary Off"
                          id="temporary-off"
                          name="delivery-off"
                          checked={isTemporaryOff}
                          onChange={() => {
                            this.setState({
                              isTemporaryOff: !isTemporaryOff,
                              isPermanentOff: false,
                              isBlacklisted: false,
                              statusChangeReason: '',
                              storeOrLLTurnOff: 'STORE_TEMP_OFF',
                              statusChangeComment: '',
                              tempOffInterval: '',
                              customTempOffInterval: '',
                            });
                          }}
                        />
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          label="Permanent Off"
                          id="permanent-off"
                          name="delivery-off"
                          checked={isPermanentOff}
                          onChange={() => {
                            this.setState({
                              isPermanentOff: !isPermanentOff,
                              isBlacklisted: false,
                              isTemporaryOff: false,
                              statusChangeReason: '',
                              storeOrLLTurnOff: 'STORE_PERMANENT_OFF',
                              statusChangeComment: '',
                              tempOffInterval: '',
                              customTempOffInterval: '',
                            });
                          }}
                        />
                        <Form.Check
                          custom
                          inline
                          type="radio"
                          label="Blacklisted"
                          id="blacklisted"
                          name="delivery-off"
                          checked={isBlacklisted}
                          onChange={() => {
                            this.setState({
                              isBlacklisted: !isBlacklisted,
                              isPermanentOff: false,
                              isTemporaryOff: false,
                              storeOrLLTurnOff: 'BLACKLISTED',
                              statusChangeReason: '',
                              statusChangeComment: '',
                              tempOffInterval: '',
                              customTempOffInterval: '',
                            });
                          }}
                        />
                      </div>
                      {
                        !isBlacklisted && (
                          <div className="pt-3">
                            <select
                              className="form-control"
                              name="storeOrLLTurnOff"
                              onChange={this.handleChange}
                              value={storeOrLLTurnOff}
                            >
                              <option value={`STORE_${isPermanentOff ? 'PERMANENT_OFF' : 'TEMP_OFF'}`}>
                                Store Turn Off
                              </option>
                              <option value={`LL_${isPermanentOff ? 'PERMANENT_OFF' : 'TEMP_OFF'}`}>
                                LL Turn Off
                              </option>
                            </select>
                          </div>
                        )
                      }
                      {
                        isTemporaryOff && (
                          <div className="pt-3">
                            <select
                              className="form-control"
                              name="tempOffInterval"
                              onChange={this.handleChange}
                              value={tempOffInterval}
                            >
                              <option value="">--Set Turn On Time--</option>
                              <option value="TWO_HOURS">2 hours</option>
                              <option value="ONE_DAYS">1 day</option>
                              <option value="TWO_DAYS">2 days</option>
                              <option value="ONE_WEEK">1 week</option>
                              <option value="ONE_MONTH">1 month</option>
                              <option value="Custom">Set Custom Date</option>
                            </select>
                            {
                              tempOffInterval === 'Custom' && (
                                <input
                                  type="datetime-local"
                                  className="form-control mt-3"
                                  value={moment(customTempOffInterval).format('YYYY-MM-DDTHH:mm')}
                                  name="customTempOffInterval"
                                  onChange={this.handleChangeDate}
                                  autoComplete="off"
                                />
                              )
                            }
                            {
                              <div
                                className="fs-1 text-danger pt-1"
                              >
                                {errors.tempOffInterval || errors.customTempOffInterval || ''}
                              </div>
                            }
                          </div>
                        )
                      }
                      <div className="pt-3">
                        {
                          isTemporaryOff && (
                            <select
                              className="form-control"
                              name="statusChangeReason"
                              onChange={this.handleChange}
                              value={statusChangeReason}
                            >
                              <option value="">--Select Reason--</option>
                              <option value="Not in the city/town for few days">Not in the city/town for few days</option>
                              <option value="Shop is closed for sometime">Shop is closed for sometime</option>
                              <option value="No one to handle online orders">No one to handle online orders</option>
                              <option value="Shop closed due to lockdown">Shop closed due to lockdown</option>
                              <option value="Others">Others</option>
                            </select>
                          )
                        }
                        {
                          !isTemporaryOff && (
                            <select
                              className="form-control"
                              name="statusChangeReason"
                              onChange={this.handleChange}
                              value={statusChangeReason}
                            >
                              <option value="">--Select Reason--</option>
                              <option value="Not getting any orders">Not getting any orders</option>
                              <option value="Settlement issue">Settlement issue</option>
                              <option value="Coupons are disabled">Coupons are disabled</option>
                              <option value="Staff issue">Staff issue</option>
                              <option value="Business permanently closed">Business permanently closed</option>
                              <option value="Product listing issue">Product listing issue</option>
                              <option value="Others">Others</option>
                            </select>
                          )
                        }
                      </div>
                      {
                        statusChangeReason === 'Others' && (
                          <div>
                            <textarea
                              className="form-control mt-3"
                              rows={5}
                              name="statusChangeComment"
                              value={statusChangeComment}
                              placeholder="Enter the reason to change the retailer settings"
                              onChange={this.handleChange}
                            />
                          </div>
                        )
                      }
                    </>
                  ) : (
                    <div>
                      <textarea
                        className="form-control"
                        rows={5}
                        name="statusChangeReason"
                        value={statusChangeReason}
                        placeholder="Enter the reason to change the retailer settings"
                        onChange={this.handleChange}
                      />
                    </div>
                  )
                }
               <div
                 className="fs-1 text-danger mb-4 pt-1 setup-input"
               >
                 {errors.statusChangeReason || errors.statusChangeComment || ''}
               </div>
               <div
                 className="text-right"
               >
                 <Button
                   onClick={this.submitReason}
                 >
                   Save
                 </Button>
                 <Button
                   variant="light ml-3"
                   onClick={() => this.setState({
                     showReasonDialog: false,
                     storeTurningOff: false,
                     statusChangeReason: storeTurningOff ? '' : statusChangeReason,
                     tempOffInterval: '',
                     customTempOffInterval: '',
                     isTemporaryOff: true,
                     isPermanentOff: false,
                     isBlacklisted: false,
                     storeOrLLTurnOff: 'STORE_TEMP_OFF',
                   })}
                 >
                   Cancel
                 </Button>
               </div>
             </div>
            )}
           onHide={() => this.setState({
             showReasonDialog: false,
             storeTurningOff: false,
             statusChangeReason: storeTurningOff ? '' : statusChangeReason,
             tempOffInterval: '',
             customTempOffInterval: '',
             isTemporaryOff: true,
             isPermanentOff: false,
             isBlacklisted: false,
             storeOrLLTurnOff: 'STORE_TEMP_OFF',
           })}
           autoSize
         />
         {
            loading ? (
              <Row
                className="justify-content-center"
              >
                <Col
                  xs="auto"
                  className="spinner-border text-primary p-0"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Col>
              </Row>
            ) : (
              <>
                <Row
                  className="settings-nav bg-white"
                >
                  <Col
                    className="p-0"
                  >
                    <Link
                      to={`/retailer${
                        isDetailPage
                          ? `/${storeCode}/?view=setting&`
                          : '-setting/?'
                      }state=store-details`}
                      replace
                    >
                      <Button
                        variant={`link rounded-0 py-3 ${
                          state === 'store-details'
                            ? 'border-bottom-primary'
                            : 'text-muted border-bottom'
                        }`}
                        block
                      >
                        Store Details
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-0 py-2"
                  >
                    <div className="border h-100" />
                  </Col>
                  <Col
                    className="p-0"
                  >
                    <Link
                      to={`/retailer${
                        isDetailPage
                          ? `/${storeCode}/?view=setting&`
                          : '-setting/?'
                      }state=delivery-details`}
                      replace
                    >
                      <Button
                        variant={`link rounded-0 py-3 ${
                          state === 'delivery-details'
                            ? 'border-bottom-primary'
                            : 'text-muted border-bottom'
                        }`}
                        block
                      >
                        Delivery Details
                      </Button>
                    </Link>
                  </Col>
                  <Col
                    xs="auto"
                    className="px-0 py-2"
                  >
                    <div className="border h-100" />
                  </Col>
                  <Col
                    className="p-0"
                  >
                    <Link
                      to={`/retailer${
                        isDetailPage
                          ? `/${storeCode}/?view=setting&`
                          : '-setting/?'
                      }state=account-details`}
                      replace
                    >
                      <Button
                        variant={`link rounded-0 py-3 ${
                          state === 'account-details'
                            ? 'border-bottom-primary'
                            : 'text-muted border-bottom'
                        }`}
                        block
                      >
                        Account Details
                      </Button>
                    </Link>
                  </Col>
                </Row>
                {
                  state === 'store-details' && (
                    <Row
                      className={`${this.checkPers([Permission.RETAILER_WRITE,
                        Permission.RETAILER_SETTING_STORE_DETAIL_WRITE]) ? '' : 'pointer-events-none'}`}
                    >
                      {
                        showFillBusinessDetailsAlert && (
                          <Col
                            xs={24}
                            className="pt-3 text-center text-danger"
                          >
                            Note - Retailer has not submitted the address yet.
                            So Retailer is not eligible for 3PL Delivery Service.
                          </Col>
                        )
                      }
                      {
                        isServiceableByDeliveryPartner && (
                        <Col
                          xs={24}
                          className="pt-3"
                        >
                          <Container>
                            <Row>
                              <Col
                                className="p-0"
                              >
                                <Button
                                  variant={`link py-1 border font-weight-bold ${
                                    deliveryOption === 'OWN_DELIVERY'
                                      ? 'border-primary'
                                      : 'text-muted border-muted'
                                  }`}
                                  block
                                  onClick={() => {
                                    this.setState({
                                      deliveryOption: 'OWN_DELIVERY',
                                    });
                                  }}
                                >
                                  Own delivery by Retailer
                                </Button>
                              </Col>
                              <Col
                                className="p-0"
                              >
                                <Button
                                  variant={`link py-1 border font-weight-bold ${
                                    deliveryOption === 'DELIVERY_SUPPORT'
                                      ? 'border-primary'
                                      : 'text-muted border-muted'
                                  }`}
                                  block
                                  onClick={() => {
                                    this.setState({
                                      deliveryOption: 'DELIVERY_SUPPORT',
                                    });
                                  }}
                                >
                                  Delivery By 3PL Service
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        )
                      }
                      {
                        deliveryOption === 'DELIVERY_SUPPORT' ? (
                          <Col
                            xs={24}
                            className="text-center"
                          >
                            <div
                              className="font-weight-bold py-3"
                            >
                              Third-Party Logistics
                            </div>
                            <div
                              className="pb-3 text-primary"
                            >
                              Delivery Radius will be served - 5Km
                            </div>
                          </Col>
                        ) : (
                          <Col
                            xs={24}
                          >
                            <Row>
                              <Col>
                                <div
                                  className="mb-2 mt-3"
                                >
                                  <b>1. Store Category</b>
                                  <span className="text-danger">*</span>
                                </div>
                                <input
                                  readOnly
                                  type="text"
                                  name="storeType"
                                  className="form-control rounded-0 setup-input"
                                  value={storeProfile ? storeProfile.storeType.displayName : ''}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mt-3"
                                >
                                  <b>2. Shop Address</b>
                                  <span className="text-danger">*</span>
                                </div>
                                <div
                                  className="d-block mb-3 pt-1 setup-input"
                                >
                                  {address}
                                </div>
                                {/* <div
                                  className="mb-4"
                                >
                                  <Link
                                    to={{
                                      pathname: '/update-location',
                                      setup: false,
                                      updatedProfile: this.state,
                                    }}
                                  >
                                    <Button
                                      variant="outline-primary"
                                      className="px-4"
                                    >
                                      <b>Change Location</b>
                                    </Button>
                                  </Link>
                                </div> */}
                                <span
                                  className="d-flex border border-radius p-2 mb-3 align-items-center setup-input"
                                >
                                  <b
                                    className="text-muted border-right px-2"
                                  >
                                    Landmark
                                  </b>
                                  <input
                                    name="landmark"
                                    className="border-0 font-weight-bold text-primary px-4 bg-white setup-input"
                                    type="text"
                                    value={landmark}
                                    onChange={this.handleChange}
                                  />
                                </span>
                                <span
                                  className={`d-flex border border-radius p-2 align-items-center setup-input ${
                                    errors.pincode ? ' border-danger' : ''}`}
                                >
                                  <b
                                    className="text-muted border-right px-2"
                                  >
                                    Pincode
                                  </b>
                                  <input
                                    name="pincode"
                                    className="border-0 font-weight-bold text-primary px-4 bg-white setup-input"
                                    type="text"
                                    value={pincode}
                                    onChange={this.handleChange}
                                  />
                                </span>
                                <div
                                  className="fs-1 text-danger mb-4 pt-1 setup-input"
                                >
                                  {errors.pincode || ''}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>3. Delivery Timings</b>
                                  <span className="text-danger">*</span>
                                </div>
                                <Row>
                                  <BusinessTimings
                                    storeCode={storeCode}
                                    businessTimings={businessTimings}
                                    setBusinessTimings={this.updateBusinessTimings}
                                  />
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>4. Minimum Shopping Amount</b>
                                </div>
                                <Row>
                                  <Col
                                    xs="auto"
                                    className="pr-1"
                                  >
                                    <div
                                      className="d-flex"
                                    >
                                      <span
                                        className="pt-2 pr-3"
                                      >
                                        <p
                                          className="fs-1"
                                        >
                                          Min. Order Value
                                        </p>
                                      </span>
                                      <span
                                        className={`d-flex border border-radius p-2 align-items-center ${
                                          errors.minimumShoppingAmount ? ' border-danger' : ''}`}
                                      >
                                        <b className="text-muted border-right px-2">{'\u20B9'}</b>
                                        <input
                                          name="minimumShoppingAmount"
                                          className="border-0 font-weight-bold text-primary w-100p px-4 bg-white"
                                          type="text"
                                          value={minimumShoppingAmount}
                                          onChange={this.handleChange}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  className="fs-1 text-danger mb-4 pt-1 setup-input"
                                >
                                  {errors.minimumShoppingAmount || ''}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>If min. shopping amount does not meet</b>
                                </div>
                                <Row>
                                  <Col
                                    xs="auto"
                                    className="pr-1"
                                  >
                                    <div
                                      className="d-flex"
                                    >
                                      <span
                                        className="pt-2 pr-3"
                                      >
                                        <p
                                          className="fs-1"
                                        >
                                          Delivery Charges
                                        </p>
                                      </span>
                                      <span
                                        className="d-flex border border-radius p-2 align-items-center"
                                      >
                                        <b className="text-muted border-right px-2">{'\u20B9'}</b>
                                        <input
                                          name="minDeliveryPenalty"
                                          disabled={
                                            parseInt(minimumShoppingAmount, 10) === 0
                                            || !minimumShoppingAmount
                                          }
                                          className={`border-0 font-weight-bold w-100p px-4 bg-white ${
                                            parseInt(minimumShoppingAmount, 10) === 0
                                            || !minimumShoppingAmount
                                              ? 'text-secondary' : 'text-primary'
                                          }`}
                                          type="text"
                                          value={
                                            (
                                              parseInt(minimumShoppingAmount, 10) === 0
                                                || !minimumShoppingAmount
                                            )
                                              ? '0' : minDeliveryPenalty
                                          }
                                          onChange={this.handleChange}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                <div className="mb-4 pt-1" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>5. Accepted Payment Methods</b>
                                  <span className="text-danger">*</span>
                                </div>
                                <Row>
                                  {
                                    paymentMode.map((mode) => (
                                      <Col
                                        xs="auto"
                                        className="pr-1 d-flex align-items-center"
                                        key={mode.code}
                                        onClick={() => this.handleChangePaymentMode(mode.code)}
                                      >
                                        <div
                                          className={`border rounded checkbox ${
                                            mode.isEnabled ? ' checked' : ''
                                          }${
                                            errors.paymentMode ? ' border-danger' : ''
                                          }`}
                                        />
                                        <div className="px-3">{mode.name}</div>
                                      </Col>
                                    ))
                                  }
                                </Row>
                                <div
                                  className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
                                >
                                  {errors.paymentMode || ''}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>6. Alternate Mobile Number</b>
                                </div>
                                <input
                                  type="text"
                                  name="alternatePhoneNumber"
                                  className={`form-control bg-white rounded-0 setup-input ${
                                    errors.alternatePhoneNumber ? ' border-danger' : ''}`}
                                  value={alternatePhoneNumber}
                                  onChange={this.handleChange}
                                />
                                <div
                                  className="fs-1 d-block text-danger mb-4 pt-1 setup-input"
                                >
                                  {errors.alternatePhoneNumber || ''}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="mb-2"
                                >
                                  <b>7. Delivery Boy Details</b>
                                </div>
                                <Row>
                                  <DeliveryBoyDetails
                                    storeCode={storeCode}
                                    deliveryBoyDetails={deliveryBoyDetails}
                                    handleRequestProcessing={this.handleLoad}
                                  />
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        )
                      }
                      <Col
                        xs={24}
                        className={`${this.checkPers([Permission.RETAILER_WRITE,
                          Permission.RETAILER_SETTING_STORE_DETAIL_WRITE]) ? '' : 'd-none'} text-center`}
                      >
                        {
                          showSuccessMessage ? (
                            <Row
                              className="m-0 mb-4 justify-content-center"
                            >
                              <Col
                                xs="auto"
                                className="bg-primary-50 rounded py-2 text-white"
                              >
                                <b>Settings Saved</b>
                              </Col>
                            </Row>
                          ) : (
                            <Row
                              className="m-0 mb-4 justify-content-center"
                            >
                              <Col
                                className="p-0"
                              >
                                <Button
                                  className="px-6"
                                  onClick={this.checkStoreDetails}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          )
                        }
                      </Col>
                    </Row>
                  )
                }
                {
                  state === 'delivery-details' && (
                    <Row>
                      <Col
                        xs={24}
                      >
                        <Row>
                          <Col>
                            <div
                              className="mb-2 mt-3 d-flex align-items-center"
                            >
                              <span>
                                <b>1. Delivery Radius</b>
                                <span className="text-danger">*</span>
                              </span>
                            </div>
                            <Row>
                              <Col
                                xs="auto"
                                className="pr-1"
                              >
                                {
                              deliveryRadii.map((radii, index) => (
                                <div
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className="d-flex"
                                >
                                  <span
                                    className="pt-2"
                                  >
                                    <p
                                      className="fs-1"
                                    >
                                      {
                                        index === 0
                                          ? 'Free Delivery Radius '
                                          : 'Add Delivery Fees'
                                      }
                                    </p>
                                  </span>
                                  <span
                                    className={`d-flex pb-4 ${index === 0 ? 'pl-2' : 'pl-4'}`}
                                  >
                                    <span
                                      className={`d-flex border border-radius align-items-center ${
                                        errors.radius ? 'border-danger' : ''} p-2`}
                                    >
                                      <input
                                        name="radius"
                                        className="border-0 font-weight-bold text-primary w-50p bg-white"
                                        type="text"
                                        value={radii.radius}
                                        onChange={(event) => {
                                          this.handleChangeRadii(index, event);
                                        }}
                                      />
                                      <b className="text-muted border-left px-2">KM</b>
                                    </span>
                                    {
                                      index !== 0
                                        && (
                                          <span
                                            className={`d-flex border border-radius align-items-center ${
                                              errors.amount ? 'border-danger' : ''} ml-2 p-2`}
                                          >
                                            <input
                                              name="amount"
                                              className="border-0 font-weight-bold text-primary w-50p bg-white"
                                              type="text"
                                              value={radii.amount}
                                              onChange={(event) => {
                                                this.handleChangeRadii(index, event);
                                              }}
                                            />
                                            <b className="text-muted border-left px-2">{'\u20B9'}</b>
                                          </span>
                                        )
                                    }
                                    {
                                      index > 0
                                        && (
                                          <span className="pt-2 pl-3">
                                            <Button
                                              className="rounded-button text-white p-0"
                                              variant="secondary"
                                              onClick={() => this.handleDeleteRadius(index)}
                                            >
                                              &times;
                                            </Button>
                                          </span>
                                        )
                                    }
                                  </span>
                                </div>
                              ))
                            }
                              </Col>
                            </Row>
                            <div
                              className="fs-1 d-block text-danger pt-1 setup-input"
                            >
                              {errors.radius || ''}
                            </div>
                            <div
                              className="fs-1 d-block text-danger pt-1 setup-input"
                            >
                              {errors.amount || ''}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="pb-4 d-flex"
                          >
                            <Button
                              className="p-0 text-success pr-4"
                              variant="link"
                              onClick={() => this.handleExtendRadii(
                                {
                                  radius: deliveryRadii.slice(-1)[0]
                                    ? parseInt(deliveryRadii.slice(-1)[0].radius, 10) + 2 : 2,
                                  amount: deliveryRadii.slice(-1)[0]
                                    ? parseInt(deliveryRadii.slice(-1)[0].amount, 10) + 10 : 0,
                                },
                              )}
                            >
                              + Extend radius with delivery Fees
                            </Button>
                            <Button
                              onClick={this.handleUpdateRadius}
                              disabled={deliveryRadiusStatus === 'loading'}
                            >
                              Update
                            </Button>
                            &nbsp;&nbsp;
                            {
                              deliveryRadiusStatus === 'error' && (
                                <div
                                  className="text-danger d-flex align-items-center"
                                >
                                  Oops Retry
                                </div>
                              )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div
                              className="mb-2"
                            >
                              <b
                                className={center.lat === 0 && center.lng === 0
                                  ? 'text-secondary' : ''}
                              >
                                2. Non Delivery Area
                              </b>
                            </div>
                            {
                            center.lat === 0 && center.lng === 0
                              ? <Row className="mb-2" />
                              : (
                                <>
                                  <Row
                                    className="mb-2"
                                  >
                                    <Col
                                      xs={12}
                                    >
                                      <DeliveryMap
                                        storeCode={storeCode}
                                        storeLocation={center}
                                        updateHexRadius={updateHexRadius}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )
                          }
                            {
                                (loading || error) && (
                                  <div
                                    className="pt-3 text-center"
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                      />
                                    ) : (
                                      <>
                                        <span
                                          className="text-danger"
                                        >
                                          Something Went Wrong
                                        </span>
                                      </>
                                    )}
                                  </div>
                                )
                              }
                            {
                                (!loading && !error) && (
                                  <Col
                                    className="pt-4 px-0 flex-grow-1 overflow-y"
                                  >
                                    <CustomTable
                                      headers={headers}
                                      content={hexLogsInfo.data}
                                      keyField="code"
                                      l={param.l}
                                      p={param.p}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      totalItems={hexLogsInfo.count}
                                      hasPrev={hexLogsInfo.prev !== ''}
                                      hasNext={hexLogsInfo.next !== ''}
                                      onNext={this.onNext}
                                      onPrev={this.onPrev}
                                      pagiationPostion="position-sticky"
                                    />
                                  </Col>
                                )
                              }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )
                }
                {
                  state === 'account-details' && (
                    <Row className={`${this.checkPers([Permission.RETAILER_WRITE,
                      Permission.RETAILER_SETTING_ACCOUNT_DETAIL_WRITE]) ? '' : 'pointer-events-none'}`}
                    >
                      {getAccountDetailsBody(false)}
                    </Row>
                  )
                }
              </>
            )
          }
       </Container>
     </>
   );
 }
}

RetailerSettings.propTypes = {
  storeCode: PropTypes.string.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
      updatedProfile: PropTypes.shape({}),
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    updatedProfile: PropTypes.shape({}),
  }).isRequired,
  isDetailPage: PropTypes.bool.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  updateRetailerDetails: PropTypes.func.isRequired,
};

export default RetailerSettings;
